import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import { Multiselect, MultiselectItem } from '../interfaces/admin.interface';
import * as ENDPOINT from '../../shared/constant/endPoint.js';

@Injectable()
export class PortalService {

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }


  getMultiselectItems(multiselectType: Multiselect, filter?: any): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.COMPANY:
        endPoint = ENDPOINT.multiselect_companyList;
        break;
      case Multiselect.PRODUCTS:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_product)+"?extended=true";
        break;
      case Multiselect.PRODUCT_FAMILY:
        endPoint = ENDPOINT.multiselect_familyList;
        break;
      case Multiselect.PRODUCT_TYPE:
        endPoint = ENDPOINT.multiselect_productType;
        break;
      case Multiselect.PRODUCT_CODE:
        endPoint = ENDPOINT.multiselect_code;
        break;
      case Multiselect.DELIVERY_AREA:
        endPoint = ENDPOINT.multiselect_delivery_area;
        break;
      case Multiselect.AVAILABILITY_PERIOD:
        endPoint = ENDPOINT.multiselect_availability_period;
        break;
      case Multiselect.UDM:
        // endPoint = ENDPOINT.product_code;
        break;
      case Multiselect.STATE:
        // endPoint = ENDPOINT.product_code;
        break;
      case Multiselect.RESELLER:
        endPoint = ENDPOINT.multiselect_reseller;
        break;
      case Multiselect.RESELLER_OWN:
        endPoint = ENDPOINT.multiselect_reseller_own;
        break;
      case Multiselect.USER:
        endPoint = ENDPOINT.multiselect_userList;
        break;
      case Multiselect.EMAIL:
        endPoint = ENDPOINT.multiselect_email;
        break;
      case Multiselect.ROLE:
        endPoint = ENDPOINT.multiselect_roles;
        break;
      case Multiselect.ROLE_LIST:
        endPoint = ENDPOINT.multiselect_rolesForList;
        break;
      case Multiselect.RESELLER_PRICE_LIST:
        endPoint = ENDPOINT.multiselect_resellerPriceList;
        break;
      case Multiselect.CURRENCY:
        endPoint = ENDPOINT.multiselect_currency;
        break;
      case Multiselect.TRANSLATION_ATTRIBUTE:
        endPoint = ENDPOINT.multiselect_translation_attribute;
        break;
      case Multiselect.CHANNEL_LIST:
        endPoint = ENDPOINT.multiselect_accessChannels_all;
        break;
      case Multiselect.PRICE_LIST:
        endPoint = ENDPOINT.multiselect_priceList_company;
        break;
      case Multiselect.RESELLER_IS_COMPANY:
        endPoint = ENDPOINT.multiselect_reseller_isCompany;
        break;
      case Multiselect.PRICE_LIST_RESELLER_IS_COMPANY:
        endPoint = ENDPOINT.multiselect_priceList_resellerIsCompany;
        break;
      case Multiselect.ORDER_CODE:
        endPoint = ENDPOINT.multiselect_orderCode;
        break;
      case Multiselect.SALE_CODE:
        endPoint = ENDPOINT.multiselect_saleCode;
        break;
      case Multiselect.CUSTOMER:
        endPoint = ENDPOINT.multiselect_customer;
        break;
      case Multiselect.ORDER_STATE:
        endPoint = ENDPOINT.multiselect_orderState;
        break;
      case Multiselect.PAYMENT_STATE:
        endPoint = ENDPOINT.multiselect_paymentState;
        break;
      case Multiselect.OBLITERATION_STATE:
          endPoint = ENDPOINT.multiselect_obliterationState;
          break;
      case Multiselect.EXTERNAL_API:
        endPoint = ENDPOINT.multiselect_externalApi;
        break;
      case Multiselect.OWNERS:
        endPoint = ENDPOINT.multiselect_owners;
        break;
      case Multiselect.PAYMENT_TYPES:
        endPoint = ENDPOINT.multiselect_payment_bo_types;
        break;
      case Multiselect.RESELLER_USER:
        endPoint = ENDPOINT.multiselect_reseller_user;
        break;
      case Multiselect.PRODUCTNAME:
        endPoint = ENDPOINT.multiselect_productname;
        break;
      case Multiselect.CUSTOMER_EMAILS:
        endPoint = ENDPOINT.multiselect_customer_emails;
      break;
    }

    return this.get(endPoint);
  }

  getBackendVersion() {
    return this.get(ENDPOINT.backend_version);
  }


  getMultiselectItemsWithParam(multiselectType: Multiselect, param: any): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.RESELLER_PRICE_LIST:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_resellerPriceList_param, param);
        break;
      case Multiselect.TRANSLATION_ATTRIBUTE:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_translationAttribute_idProduct, param);
        break;
      case Multiselect.PRODUCT_FAMILY:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_familyList_idCompany, param);
        break;
      case Multiselect.PRICE_LIST:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_priceList_company_idCompany, param);
        break;
      case Multiselect.PRICE_LIST_RESELLER_IS_COMPANY:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_priceList_resellerIsCompany_idReseller, param);
        break;
      case Multiselect.PRODUCTS:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_product_idPriceList, param) + "&extended=true";
        break;
      case Multiselect.CUSTOMER_EMAILS:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_customer_emails, param);
      break;
    }
    return this.get(endPoint);
  }

  getItemsFromData(data): MultiselectItem[] {
    return data.map((d) => {
      return {
        id: d.id,
        itemName: d.name
      };
    });
  }

  getItemsFromDataNoId(data): MultiselectItem[] {
    let index = 1;
    return data.map((d) => {
      return {
        id: index++,
        itemName: d.name
      };
    });
  }

  getItemsFromEnum(enumObject): MultiselectItem[] {
    const items = [];
    let index = 0;
    // tslint:disable-next-line: forin
    for (const key in enumObject) {
      items.push({
        id: index++,
        itemName: key
      });
    }
    return items;
  }

  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



