import { Component, Input } from '@angular/core';
import { ProductCartStatus } from '@app/shared/models/requests';

interface IStatoBadge {
  class: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'app-product-cart-state',
  templateUrl: './product-cart-state.component.html',
  styleUrl: './product-cart-state.component.scss'
})
export class ProductCartStateComponent {

  @Input() state: ProductCartStatus.ALL;

  styles: { [key in ProductCartStatus] : IStatoBadge } = {
    VALID: { class: 'bg-info', icon:'', label: ''  },
    UNDER_APPROVAL: { class: 'bg-warning', icon:'', label: ''  },
    APPROVED: { class: 'bg-success', icon:'', label: ''  },
    NOT_APPROVED: { class: 'bg-danger', icon:'', label: '' },
    DELETED: { class: 'bg-dark', icon:'', label: '' },
    ALL: { class: 'bg-light', icon:'', label: '' },
  }

}
