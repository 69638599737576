import { Component, Input } from '@angular/core';
import { ProductCartStatus } from '@app/shared/models/requests';
import { RequestApiService, IListaRequestOrderParams } from '@app/shared/services/request-api.service';

@Component({
  selector: 'sac-widget-richieste',
  templateUrl: './widget-richieste.component.html',
  styleUrl: './widget-richieste.component.scss'
})
export class WidgetRichiesteComponent {

  @Input() set dateFrom(newValue: Date) {
    this._dateFrom = newValue;
    this.search();
  }

  @Input() set dateTo(newValue: Date) {
    this._dateTo = newValue;
    this.search();
  }

  @Input() label: string;
  @Input() icon: string;
  @Input() miniMode: boolean = false;

  _dateFrom: Date;
  _dateTo: Date;

  value: number; 

  constructor(private requestApiService: RequestApiService) { }

  private search() {

    if (!this._dateFrom || !this._dateTo) {
      return
    }

    const params: IListaRequestOrderParams = {
      paging: true,
      page: 0,
      rows: 4,
      orderBy: "tsCreated",
      direction: "DESC",
      summary: true,
      filters: {
        CART: {
          fromDate: this._dateFrom,
          toDate: this._dateTo,
          productStatus: [ProductCartStatus.UNDER_APPROVAL] //, ProductCartStatus.NOT_APPROVED] 
        }
      }
    }

    this.requestApiService.searchListaRichieste(params).subscribe(richiestePaginate => {
      this.value = richiestePaginate.total;
    })

  }

}
