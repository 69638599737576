<div  class="card bg-primary border-0 mb-0 {{ miniMode ? 'rounded-pill' : '' }}">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col-3">
                <em class="fas fa-question-mark {{ icon }} {{ miniMode ? '' : 'fa-5x' }}"></em>
            </div>
            <div class="col-9 text-right">
                <div class="{{ miniMode ? '' : 'text-lg' }}">{{ value }}</div>
                <p class="m-0 h4" *ngIf="!miniMode">{{ label | translate }}</p>
            </div>
        </div>
    </div>
</div>