import { Injectable } from '@angular/core';
import { IS1SearchParamsAlt, S1HttpClientService } from '@app/s1/services/s1-http-client.service';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { Observable } from 'rxjs/internal/Observable.js';
import { map } from 'rxjs';
import { IRequest, ProductCartStatus } from '../models/requests.js';
import { saveAs } from 'file-saver/src/FileSaver.js';
import { dateFormatting } from '../../../assets/js/util.js';

export interface IListaRequestOrderParams extends IS1SearchParamsAlt {
  summary: boolean,
  filters?: {
    CART?: {
      fromDate?: Date,
      toDate?: Date,
      serviceRequestFrom?: Date,
      serviceRequestTo?: Date,
      productStatus?: ProductCartStatus[]
    }
  }
}

export interface IUpdateStatoRichiestaParams {
  idCartItem: number;
  nextStatus: ProductCartStatus
}

export interface IRichiestePaginate {
  requests: IRequest[],
  total: number
}

@Injectable({
  providedIn: 'root'
})
export class RequestApiService {

  constructor(private s1HttpClient: S1HttpClientService) { }

  public searchListaRichieste(params: IListaRequestOrderParams): Observable<IRichiestePaginate> {

    return this.s1HttpClient.post(ENDPOINT.order_cart_all, params, false).pipe(
      map(response => { 
        return { requests: response.data, total: response.total } as IRichiestePaginate
      })
    )

  }

  public updateStatoRichiesta(params: IUpdateStatoRichiestaParams): Observable<boolean> {
    return this.s1HttpClient.put(ENDPOINT.order_cart_update_status.replace(":idCartItem", params?.idCartItem).replace(":orderStatus", params?.nextStatus), false).pipe(
      map(response => { 
        return response.outcome.success
      })
    )
  }

  downloadAnalysisRequestsReport(reqBody: IListaRequestOrderParams): Observable<any> {
    let endPoint = ENDPOINT.order_cart_all_export;

    return this.s1HttpClient.downloadPost(endPoint, reqBody).pipe(
      map(response => {
        saveAs(response, "requests_"+dateFormatting(new Date())+".xls");
        return response
      })
    )

  }

}
