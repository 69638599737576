import { AdditionalInfo } from "@app/core/interfaces/cart-item.interface";

export interface IRequest {    
    id: number,
    idCustomer: number,
    customerEmail: string,
    customerName: string,
    customerSurname: string,
    customerBusinessName: string,
    productCode: string,
    productFamilyName: string,
    identificationName: string,
    quantity: 1,
    productStatusCart: ProductCartStatus,
    tsCreated: string,
    additionalInfo: AdditionalInfo
}

export enum ProductCartStatus {
    "VALID" = "VALID",
    "UNDER_APPROVAL" = "UNDER_APPROVAL",
    "APPROVED" = "APPROVED",
    "NOT_APPROVED" = "NOT_APPROVED",
    "DELETED" = "DELETED",
    "ALL" = "ALL"
  }