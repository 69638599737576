export const backend_version =          "/version";

export const priceList_reseller =       "/list/price/reseller/:idReseller/:companyCode";
export const order_productFamily =      '/product/product_family/:idReseller/:companyCode';
export const product_productListGroup = "/product/product_list_group/:idReseller/:companyCode/:currency/:languageCode";
export const products_groupByFamily =       "/:permalink/products/group_by_family/:idReseller/:companyCode/:currency/:languageCode"

export const company_all =     "/company/all";
export const company_info =    "/company/info/:idCompany";
export const company_create =  "/company/create";
export const company_update =  "/company/update";

export const reseller_landingInfo =       "/reseller/landing/info/:idReseller";
export const reseller_info =              "/reseller/info/:idReseller";
export const reseller_all =               "/reseller/all";
export const reseller_deleteAssociation = "/reseller/delete_association/:idReseller/with/:idCompany";
export const reseller_createReseller =    "/reseller/create";
export const reseller_update =            "/reseller/update";

export const delivery_area_all =          "/reseller/delivery_area/all";
export const delivery_area_new =          "/reseller/delivery_area/new";
export const delivery_area_info =         "/reseller/delivery_area/:idDeliveryArea";
export const delivery_area_update =       "/reseller/delivery_area/update";
export const delivery_area_delete =       "/reseller/delivery_area/delete/:idReseller/:idDeliveryArea";
export const day_time_all =               "/reseller/availability/day_time/all";
export const day_time_info =              "/reseller/availability/day_time/:idResellerAvailabilityDayTime";
export const day_time_create =            "/reseller/availability/day_time/new";
export const day_time_update =            "/reseller/availability/day_time/update";
export const day_time_delete =            "/reseller/availability/time/delete/:idReseller/:idAvailabilityDayTime";
export const availability_all =           "/reseller/availability/all";
export const availability_new =           "/reseller/availability/new";
export const availability_update =        "/reseller/availability/update";
export const availability_info =          "/reseller/availability/:idAvailability";
export const availability_delete =        "/reseller/availability/delete/:idReseller/:idAvailability";
export const availability_reservation =   "/reseller/availability/reservation/:idReseller/:idAvailabilityDayTime";


//export const order_cloudProducts =       "/order/cloud_products";  NON UTILIZZATO
export const order_orderSummary =        "/order/summary/:language"; 
export const order_customerOrder =       "/order/create";
export const order_orderService =        "/order/order_service/:language"; //credo non venga utilizzato
export const order_attemptedPayment =    "/order/action/attempted_payment"
export const order_paymentSuccess =      "/order/payment-success"; //NON CREDO VENGA UTILIZZATA
export const order_purchaseSummary =     "/order/purchase_summary/:language";  //NON CREDO VENGA UTILIZZATA
export const order_refund =              "/order/refund/:idOrder";

export const order_cart_view =              "/order/cart/view/:languageCode";
export const order_cart_add =               "/order/cart/add/:languageCode";
export const order_cart_remove =            "/order/cart/remove/:idCartItem";
export const order_cart_empty =             "/order/cart/empty";
export const order_cart_checkout =          "/order/cart/checkout/:languageCode/:paymentMethod";
export const order_cart_lotcheckout =       "/order/cart/lotcheckout";
export const order_cart_all =               "/order/cart/all";
export const order_cart_all_export =        "/order/cart/all/export";
export const order_cart_update_status =     "/order/cart/updateStatus/:idCartItem?newStatus=:orderStatus";

export const license_all =         "/license/all";
export const license_licenseCode = "/license/:licenseCode";

export const admin_user =                       "/admin/user";
export const admin_user_userSession_info =      "/admin/user/user_session/info"
export const admin_user_userSession_update =    "/admin/user/user_session/update"
export const admin_userId =                     "/admin/user/:idUser";
export const admin_userInvite =                 "/admin/user/invite/:idUser";
export const admin_userList =                   "/admin/user/list/";
export const admin_userCompany =                "/admin/user/company";
export const admin_userBusinessName =           "/admin/user/business_name";
export const admin_user_privilige =             "/admin/user/privilege";
export const admin_user_enable =                "/admin/user/enable/:idUser";
export const admin_user_disable =               "/admin/user/disable/:idUser";
export const admin_user_myReseller =            "/admin/user/myreseller";
export const admin_user_allReseller =           "/admin/user/allreseller";
export const admin_user_session_metadata =      "/admin/user/user_session/metadata";

export const account_changepwd =     "/account/changepwd";

export const listOrder_all               = "/list/order/all";
export const listOrder_action            = "/list/order/action";
export const listOrder_orderDetail       = "/list/order/order_detail/:idOrder";
export const listCustomer_all            = "/list/customer/all";
export const listCustomer_actionBlock    = "/list/customer/action/block/:idCustomer";
export const listCustomer_actionActive   = "/list/customer/action/active/:idCustomer";
export const listCustomer_actionDelete   = "/list/customer/action/delete/:idCustomer";
export const listOrder_allCustomer       = "/list/order/customer/:idCustomer";
export const listOrder_allExport         = "/list/order/all/export";
export const listCustomer_allExport      = "/list/customer/all/export";

export const product_listView =                                 "/product/all";
export const product_idProduct =                                "/product/:idProduct";
export const product_createProduct =                            "/product/create_product"
export const product_updateRegistry =                           "/product/update_product"
export const product_create_ThresholdAttribute =                "/product/create/threshold_attribute"
export const product_update_ThresholdAttribute =                "/product/update/threshold_attribute";
export const product_idProductFamily =                          "/product/productFamily/:idProductFamily";
export const product_productFamily =                            "/product/productFamily/all";
export const product_createProductFamily =                      "/product/create_product_family";
export const product_updateProductFamily =                      "/product/update_product_family";
export const product_deleteProductFamily =                      "/product/product_family/action/disable/:idProductFamily";
export const product_activateProductFamily =                    "/product/product_family/action/active/:idProductFamily";
export const product_productFamily_idFamily =                   "/product/productFamily/:idFamily";
export const product_productFamily_update_image =               "/product/product_family/update/image";
export const product_productFamily_delete_image =               "/product/product_family/delete/image/:idImage";
export const product_priceList =                                "/product/price_list/:idPriceList";
export const product_productsInfo =                             "/product/products/info";
export const product_groupByFamily_idReseller_idPriceList =     "/product/group_by_family/:idReseller/:idPriceList/:currency/:languageCode";
export const product_update_media =                             "/product/update/media";
export const product_delete_media =                             "/product/delete/media/:idImage";

export const ticket_all =                             "/ticket/all";
export const ticket_edit =                            "/ticket/edit";
export const ticket_disable =                         "/ticket/action/disable/:idTicket";
export const ticket_singleInfo =                      "/ticket/single/:idTicket";
export const ticket_allExport =                       "/ticket/all/export";
export const ticket_reactivate =                      "/ticket/action/reactivate/:idTicket";
export const ticket_send_reminder =                   "/ticket/action/remind/:idTicket";
export const ticket_refund_servicepay =               "/ticket/action/refund/:idTicket"
export const ticket_by_order =                        "/ticket/byOrder/:idOrder"

export const translationAttribute_listView =    "/translationAttribute/all";
export const translationAttribute_create =      "/translationAttribute/create";
export const translationAttribute_update =      "/translationAttribute/update";

export const listPrice_all =                        "/list/price/all";
export const listPrice_infoIdPriceList =            "/list/price/info/:idPriceList";
export const listPrice_create =                     "/list/price/create";
export const listPrice_update =                     "/list/price/update";
export const listPrice_actionBlock =                "/list/price/action/block/price_list/:idPriceList";
export const listPrice_actionActive =               "/list/price/action/active/price_list/:idPriceList";
export const listPricePrice_actionBlock =           "/list/price/action/block/price_list_product/:idPriceList/:idProduct";
export const listPricePrice_actionActive =          "/list/price/action/active/price_list_product/:idPriceList/:idProduct";
export const listPrice_duplicate =                  "/list/price/duplicate";
export const associated_reseller =                  "/price/:idPriceList/reseller";

export const obliteration_all =                     "/obliteration/all";
export const obliteration_new =                     "/obliteration/new";
export const obliteration_export =                  "/obliteration/all/export";
export const obliteration_cancel =                  "/obliteration/cancel"

export const faq_all =                              "/faq/all";
export const faq_header =                           "/faq/header";
export const faq_info =                             "/faq/header/:faqId";
export const faq_detail =                           "/faq/detail";
export const faq_detail_cancel =                    "/faq/detail/:idFaqDetail";
export const faq_detail_info =                      "/faq/detail/:idFaqDetail";

export const owner =                                "/owner";
export const owner_all =                            "/owner/all";
export const owner_info =                           "/owner/:idOwner";
export const owner_enable =                         "/owner/enable/:idOwner";
export const owner_disable =                        "/owner/disable/:idOwner";

export const multiselect_email =                                    "/multiselect/email";
export const multiselect_rolesForList =                             "/multiselect/roles_for_list";
export const multiselect_roles =                                    "/multiselect/roles";
export const multiselect_companyList =                              "/multiselect/company_list";
export const multiselect_reseller =                                 "/multiselect/reseller";
export const multiselect_reseller_own =                             "/multiselect/reseller/own";
export const multiselect_userList =                                 "/multiselect/user_list";
export const multiselect_familyList_idCompany =                     "/multiselect/family_list/:idCompany";
export const multiselect_familyList =                               "/multiselect/family_list";
export const multiselect_product =                                  "/multiselect/product_dropdown";
export const multiselect_product_idPriceList =                      "/multiselect/product_dropdown/:idPriceList";
export const multiselect_productType =                              "/multiselect/product_type_dropdown";
export const multiselect_code =                                     "/multiselect/code_dropdown";
export const multiselect_resellerPriceList =                        "/multiselect/reseller_priceList";
export const multiselect_resellerPriceList_param =                  "/multiselect/reseller_priceList/:idPriceList";
export const multiselect_currency =                                 "/multiselect/currencies";
export const multiselect_delivery_area =                            "/multiselect/delivery_area";
export const multiselect_delivery_area_param =                      "/multiselect/delivery_area/:idReseller";
export const multiselect_availability_period =                      "/multiselect/availability_period";
export const multiselect_availability_period_param =                "/multiselect/availability_period/:idDeliveryArea";
export const multiselect_translation_attribute =                    "/multiselect/translation_attribute";
export const multiselect_translationAttribute_idProduct =           "/multiselect/translation_attribute/:idProduct";
export const multiselect_accessChannels_all =                       "/multiselect/access_channels/all";
export const multiselect_reseller_isCompany =                       "/multiselect/reseller/is_company";
export const multiselect_priceList_resellerIsCompany =              "/multiselect/priceList/reseller_is_company";
export const multiselect_priceList_resellerIsCompany_idReseller =   "/multiselect/priceList/reseller_is_company/:idReseller";
export const multiselect_customer =                                 "/multiselect/customers";
export const multiselect_orderCode =                                "/multiselect/orders";
export const multiselect_orderState =                               "/multiselect/order/states/all";
export const multiselect_paymentState =                             "/multiselect/payment/states/all";
export const multiselect_obliterationState =                        "/multiselect/obliteration/states/all";
export const multiselect_saleCode =                                 "/multiselect/sales";
export const multiselect_externalApi =                              "/multiselect/api_fields/all";
export const multiselect_owners =                                   "/multiselect/owners";
export const multiselect_payment_bo_types =                         "/multiselect/payment_types";
export const multiselect_reseller_user =                            "/multiselect/reseller/user";
export const multiselect_productname =                              "/multiselect/productname_dropdown";
export const multiselect_customer_emails =                          "/multiselect/customer_emails/:email";

// da eliminare
export const multiselect_priceList_company_idCompany =      "/multiselect/priceList/resellerCompany/:idCompany";
export const multiselect_priceList_company =                "/multiselect/priceList/resellerCompany";

export const statusLog_all =                                          "/statuslog/list";