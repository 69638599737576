const Dashboard = {
    text: 'Dashboard',
    translate: 'menu.dashboard',
    link: '/admin/dashboard',
    icon: 'icon-pie-chart'
};

const Home = {
    text: 'Home',
    translate: 'menu.home',
    link: '/admin/home',
    icon: 'icon-home'
};

const UserProfile = {
    text: 'Profile User',
    translate: 'menu.userProfile',
    link: '/admin/user-profile',
    icon: 'icon-pencil'
};

const Users = {
    text: 'User',
    translate: 'menu.users',
    link: '/admin/users',
    icon: 'icon-user'
};

const Company = {
    text: 'Orders',
    translate: 'menu.companies',
    link: '/admin/company',
    icon: 'icon-grid'
};

const Resellers = {
    text: 'Resellers',
    translate: 'menu.resellers',
    link: '/admin/resellers',
    icon: 'icon-organization'
};

const Products = {
    text: 'Products',
    translate: 'menu.products',
    link: '/admin/products',
    icon: 'icon-tag',
    submenu: [
        {
            text: 'Registry',
            translate: 'menu.registry',
            icon: 'icon-note',
            link: '/admin/products/registry',
        },
        {
            text: 'Combo',
            translate: 'menu.combo',
            icon: 'fa fa-copy',
            link: '/admin/products/combo',
        },
        {
            text: 'Attributes',
            translate: 'menu.attribute',
            icon: 'icon-star',
            link: '/admin/products/attribute',
        },
        {
            text: 'Family',
            translate: 'menu.family',
            icon: 'icon-puzzle',
            link: '/admin/products/family'
        }
    ]
};

const AreasAvailability = {
    text: 'Areas & Availability',
    translate: 'menu.areas_availability',
    link: '/admin/areas-and-availability',
    icon: 'icon-map',
    submenu: [
        {
            text: 'Zone',
            translate: 'menu.zone',
            icon: 'icon-location-pin',
            link: '/admin/areas-and-availability/delivery-area',
        },
        {
            text: 'Period',
            translate: 'menu.period',
            icon: 'icon-calendar',
            link: '/admin/areas-and-availability/period'
        },
        {
            text: 'Availability',
            translate: 'menu.availability',
            icon: 'icon-check',
            link: '/admin/areas-and-availability/availability'
        }
    ]
};

export const Analysis = {
    text: 'Analysis',
    translate: 'menu.analysis',
    link: '/admin/analysis',
    icon: 'icon-notebook',
    submenu: []
};

export const StatOrder = {
    text: 'Orders',
    translate: 'menu.orders',
    icon: 'icon-bag',
    link: '/admin/analysis/orders',
};

export const StatProduct = {
    text: 'Products',
    translate: 'menu.products',
    icon: 'icon-basket-loaded',
    link: '/admin/analysis/products'
};

export const StatRequest = {
    text: 'Requests',
    translate: 'menu.requests',
    icon: 'icon-question',
    link: '/admin/analysis/requests'
};

const Price = {
    text: 'Pricing',
    translate: 'menu.pricing',
    link: '/admin/price',
    icon: 'icon-book-open'
};

const ProductSales = {
    text: 'Product sales',
    translate: 'menu.product_sales',
    link: '/admin/product-sales',
    icon: 'icon-basket'
};

const ProductSalesCompanyAdmin = {
    text: 'Product sales',
    translate: 'menu.product_sales',
    link: '/admin/product-sales',
    icon: 'icon-basket',
    submenu: [
        {
            text: 'Standard',
            translate: 'menu.standard',
            link: '/admin/product-sales',
            icon: 'icon-basket',
        },
        {
            text: 'Massiva',
            translate: 'menu.lots',
            link: '/admin/lots',
            icon: 'icon-layers'
        }
    ]
};

const Customers = {
    text: 'Customers',
    translate: 'menu.customer',
    link: '/admin/customer',
    icon: 'fas fa-users'
};

export const Obliteration = {
    text: 'Obliteration',
    translate: 'menu.obliterations',
    link: '/admin/obliteration',
    icon: 'icon-doc',
    submenu: []
};

export const OblAdd = {
    text: 'Manual entry',
    translate: 'menu.manualEntry',
    link: '/admin/obliteration/manual-entry',
    icon: 'icon-calculator'
};

export const OblList = {
    text: 'History',
    translate: 'menu.obliterationHistory',
    link: '/admin/obliteration/history',
    icon: 'icon-reload'
};

export const StatusLog = {
    text: 'Status Log',
    translate: 'menu.status_log',
    link: '/admin/status-log',
    icon: 'fas fa-stream'
};

export const Lots = {
    text: 'Lots',
    translate: 'menu.lots',
    link: '/admin/lots',
    icon: 'icon-layers'
};

export const OwnerSection = {
    text: 'Owner',
    translate: 'menu.owner',
    icon: 'fa fa-user-check',
    link: '/admin/products/owner'
}

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        }
    ]
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const Media = {
    text: 'media',
    translate: 'menu.media',
    link: '/admin/media',
    icon: 'fas fa-images',
    submenu: [
        {
            text: 'media',
            translate: 'menu.list',
            link: '/admin/media',
            icon: 'fas fa-images',
        },
        {
            text: 'chiavi',
            translate: 'menu.keys',
            link: '/admin/media/keys',
            icon: 'fas fa-key',
        }
    ]
};

const SettingsAdmin = {
    text: 'Settings',
    translate: 'menu.settings',
    link: '/admin/settings',
    icon: 'fas fa-cog',
    submenu: [
        {
            text: 'Mailing List',
            translate: 'menu.mailing-list',
            link: '/admin/settings/mailing-list',
            icon: 'icon-envelope',
        },
        {
            text: 'FAQ',
            translate: 'menu.faqs',
            link: '/admin/settings/faqs',
            icon: 'fas fa-question-circle'
        }
    ]
};

const DiscountsAdmin = {
    text: 'Discounts',
    translate: 'menu.discounts',
    link: '/admin/discounts',
    icon: 'fas fa-percentage',
    submenu: [
        {
            text: 'Discounts',
            translate: 'menu.discounts_list',
            link: '/admin/discounts',
            icon: 'fas fa-percent',
        },
        {
            text: 'Coupons',
            translate: 'menu.coupons',
            link: '/admin/discounts/coupons',
            icon: 'fas fa-ticket-alt',
            submenu: [
                {
                    text: 'Coupons',
                    translate: 'menu.coupons_list',
                    link: '/admin/discounts/coupons',
                    icon: 'fas fa-th-list',
                },
                {
                    text: 'Reedem',
                    translate: 'menu.coupons_redeem',
                    link: '/admin/discounts/coupons/redeem',
                    icon: 'fas fa-clipboard-check',
                }
            ]
        }
    ]
};

// Price,
        // Orders
        // Products
export let MENU = {
    'SUPERADMIN': [
        Dashboard,
        UserProfile,
        Users,
        Company,
        Resellers,
        Customers,
        Products,
        Price,
        DiscountsAdmin,
        Analysis,
        Obliteration,
        Media,
        StatusLog,
        SettingsAdmin
    ],
    'HEADQUARTER': [],
    'SHOP': [],
    'COMPANY_ADMIN': [
        Dashboard,
        UserProfile,
        Users,
        Resellers,
        Customers,
        Products,
        Price,
        DiscountsAdmin,
        ProductSalesCompanyAdmin,
        Analysis,
        Obliteration,
        Media,
        SettingsAdmin
    ],
    'COMPANY_WORKER': [
        Dashboard,
        UserProfile,
        Analysis,
        Obliteration
    ],
    'RESELLER_ADMIN': [
        Dashboard,
        UserProfile,
        ProductSales,
        Analysis,
        Obliteration
    ],
    'RESELLER_AGENT': [
        Users,
        UserProfile,
        Customers,
        Price,
        Products
    ],
    'COCLI_USER': [
        Customers,
        Analysis
    ]
};

